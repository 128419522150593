import React, { useEffect, useState } from "react";
import styles from "./modalPassword.module.css";
import NumPad from "../NumPad/NumPad";
import Button from "../Button/button";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../router/Route";
import { log } from "console";

interface Props {
  onPress?: () => void;
  exitFromSystem: any;
  open: Boolean;
  userInfo: any;
}

type ResponseType = {
  success: boolean;
  data: string;
};

const BranchModal: React.FC<Props> = ({
  open,
  onPress,
  exitFromSystem,
  userInfo,
}) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const [stepModal, setStepModal] = useState(1);
  const [passValue, setPassValue] = useState("");
  const [showNumericKeyboard, setShowNumericKeyboard] = useState(false);

  useEffect(() => {
    dispatch.OtherSlice.getBranches();
  }, []);

  const handleNumericButtonClick = (value: string) => {
    if (passValue.length > 7) return;

    setPassValue(passValue + value);
  };

  const handleBackspace = () => {
    setPassValue(passValue.slice(0, -1));
  };

  const logout = () => {
    localStorage.removeItem("@token");
    localStorage.removeItem("showedInstructions");
    localStorage.removeItem("@checkToken");
    dispatch.profileSlice.logout();
    navigation(APP_ROUTES.BANNER);
  };

  const sendNewPass = async () => {
    const api = "https://api.smart-dunyo.uz/api/v1/user/reset-password";
    const data = {
      password: userInfo.password,
      de_password: passValue,
    };

    await fetch(api, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${localStorage.getItem("@token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.password === passValue) {
          toast.success("Пароль успешно изменен");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      });
  };

  const handleCloseModal = () => {
    if (onPress && exitFromSystem) {
      onPress();
      setPassValue("");
      setShowNumericKeyboard(false);
    } else {
      toast.error("Смените пароль! Выход из системы!");
      setTimeout(() => {
        logout();
      }, 4000);
    }
  };

  useEffect(() => {
    if (!open) {
      setStepModal(1);
    }
  }, [open]);

  return (
    <>
      <div
        className={`${styles.popup}`}
        style={{
          opacity: open ? "1" : "0",
          visibility: open ? "inherit" : "hidden",
        }}
        onClick={handleCloseModal}
      >
        <div
          className={styles.modal}
          style={{
            scale: open ? "1" : "0.95",
            transition: "0.1s linear",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {stepModal === 1 && (
            <>
              <div style={{ fontSize: "24px" }}>Изменить пароль</div>
              <div
                style={{
                  width: "100%",
                  margin: "auto",
                  fontSize: "18px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h4 className={styles.labelInput}>Старый пароль</h4>
                <input
                  type="text"
                  style={{
                    width: "100%",
                    padding: "12px",
                    margin: "auto",
                    fontSize: "18px",
                    marginBottom: "20px",
                  }}
                  name="oldPass"
                  placeholder="Старый пароль"
                  readOnly={true}
                  value={userInfo.password}
                  id="userOldPass"
                />

                <h4 className={styles.labelInput}>Новый пароль</h4>
                <input
                  type="number"
                  style={{
                    width: "100%",
                    padding: "12px",
                    margin: "auto",
                    fontSize: "18px",
                  }}
                  name="newPass"
                  placeholder="Новый пароль (не менее 8 символов)"
                  id="userNewPass"
                  value={passValue}
                  onClick={() => {
                    setShowNumericKeyboard(!showNumericKeyboard);
                  }}
                  onChange={(e) => {
                    setPassValue(e.target.value);
                  }}
                />
              </div>
              <div className={styles.buttons}>
                <Button
                  btnSize="large"
                  btnType="primary"
                  title="Изменить"
                  style={{ width: "48%" }}
                  onPress={sendNewPass}
                  disabled={passValue.length < 8 || passValue.length > 8}
                />
                <Button
                  onPress={handleCloseModal}
                  btnSize="large"
                  btnType="primary"
                  title="Отмена"
                  style={{ width: "48%", background: "grey" }}
                />
              </div>
              {showNumericKeyboard ? (
                <div className={styles.keyBoard}>
                  <NumPad
                    onButtonClick={handleNumericButtonClick}
                    onBackspace={handleBackspace}
                  />
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BranchModal;
