import React, { CSSProperties } from "react";
import { ArrowRight } from "../../assets/icons";
import GreyText from "../GreyText/greyText";
import styles from "./iconComp.module.css";

interface Props {
  icon: any;
  text?: string;
  onPress?: () => void;
  backColor?: string;
  style?: CSSProperties;
  iconType: "primary" | "outline";
  disabled?: boolean;
}

const IconComp: React.FC<Props> = ({
  icon,
  text,
  onPress,
  backColor,
  style,
  iconType,
  disabled,
}) => {
  return (
    <div className={styles.container}>
      <div
        className={`${styles[iconType]}`}
        style={{
          ...style,
          backgroundColor: backColor,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={disabled ? () => {} : onPress}
      >
        {icon}
      </div>
      {/* <GreyText text={text} style={{ whiteSpace: 'nowrap' }} /> */}
      <div style={{fontSize: "16px", textAlign: "center", whiteSpace: 'nowrap'}}>{text}</div>
    </div>
  );
};

export default IconComp;
