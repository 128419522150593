import React, { useEffect, useState } from "react";
import styles from "./branchModal.module.css";
import Button from "../Button/button";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../router/Route";
import { FormControlLabel, Switch } from "@mui/material";
import DisactiveModal from "../disactiveModal/disactiveModal";

interface Props {
  onPress?: () => void;
  handleClose?: () => void;
  open: Boolean;
}

type ResponseType = {
  success: boolean;
  data: string;
};

const BranchModal: React.FC<Props> = ({ open, onPress, handleClose }) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  useEffect(() => {
    dispatch.OtherSlice.getBranches();
  }, []);

  const [stepModal, setStepModal] = useState(1);
  const [branchId, setBranchId] = useState("");

  const { branchesList } = useSelector((state: RootState) => state.OtherSlice);

  const logout = () => {
    localStorage.removeItem("@token");
    localStorage.removeItem("showedInstructions");
    localStorage.removeItem("@checkToken");
    dispatch.profileSlice.logout();
    navigation(APP_ROUTES.WELCOME);
  };

  useEffect(() => {
    if (!open) {
      setStepModal(1);
    }
  }, [open]);

  return (
    <>
      <div
        className={`${styles.popup}`}
        style={{
          opacity: open ? "1" : "0",
          visibility: open ? "inherit" : "hidden",
        }}
        onClick={onPress}
      >
        <div
          className={styles.modal}
          style={{
            scale: open ? "1" : "0.95",
            transition: "0.1s linear",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {stepModal === 1 && (
            <>
              <div style={{ fontSize: "24px", marginBottom: "24px" }}>
                Перенос пользователя
              </div>
              <select
                onChange={(e) => setBranchId(e.target.value)}
                style={{
                  width: "100%",
                  padding: "12px",
                  margin: "auto",
                  fontSize: "18px",
                }}
              >
                <option value="">Выберите</option>
                {branchesList?.map((item, idx) => (
                  <option value={item?.id} key={idx}>
                    {item?.name}
                  </option>
                ))}
              </select>
              <Button
                onPress={() => {
                  if (branchId) {
                    setStepModal(2);
                  } else {
                    toast.error("Сначала выберите");
                  }
                }}
                btnSize="large"
                btnType="primary"
                title="Подтвердить"
                style={{ width: "100%" }}
              />
            </>
          )}
          {stepModal === 2 && (
            <>
              <div style={{ fontSize: "24px", marginBottom: "24px" }}>
                Вы подтверждаете?
              </div>
              <div
                style={{
                  width: "100%",
                  gap: "1rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  onPress={() => {
                    dispatch.OtherSlice.changeBranch(Number(branchId))
                      .then((res: ResponseType) => {
                        if (res && res?.success) {
                          setStepModal(1);
                          handleClose && handleClose();
                          toast.success(res?.data);
                          logout();
                        }
                        console.log("res", res);
                      })
                      .catch((err: Error) => {
                        console.log(err);
                      });
                  }}
                  btnSize="large"
                  btnType="primary"
                  title="Да"
                  style={{ width: "100%" }}
                />
                <Button
                  onPress={() => {
                    setStepModal(1);
                  }}
                  btnSize="large"
                  btnType="primary"
                  title="Назад"
                  style={{ width: "100%" }}
                />
              </div>
            </>
          )}
          {stepModal === 3 && (
            <>
              <div style={{ fontSize: "24px", marginBottom: "24px" }}>
                Вы точно хотите деактивировать свой акаунт?
              </div>
              <div
                style={{
                  width: "100%",
                  gap: "1rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  //   onPress={handleConfirmed}
                  onPress={() => {
                    dispatch.OtherSlice.peremDeactiv();
                    setStepModal(1);
                    logout();
                  }}
                  btnSize="large"
                  btnType="primary"
                  title="Да"
                  style={{ width: "100%" }}
                />
                <Button
                  onPress={() => {
                    setStepModal(1);
                  }}
                  btnSize="large"
                  btnType="primary"
                  title="Назад"
                  style={{ width: "100%" }}
                />
              </div>
            </>
          )}
          {stepModal !== 3 && (
            <Button
              onPress={() => {
                setStepModal(3);
              }}
              btnSize="large"
              btnType="primary"
              title="Деактивировать аккаунт"
              style={{ width: "100%", marginTop: "20px", backgroundColor: "#ff4242" }}
            />
          )}
        </div>
      </div>
      {/* <DisactiveModal
        open={disactiveModal}
        onPress={() => setDisactiveModal(false)}
        handleClose={() => setDisactiveModal(false)}
        handleConfirmed={() => {
          dispatch.OtherSlice.peremDeactiv();
          setDisactiveModal(false);
          logout();
        }}
      /> */}
    </>
  );
};

export default BranchModal;
