// NumPad.tsx
import React from "react";
import styles from "./numPad.module.css";

interface NumPadProps {
  onButtonClick: (value: string) => void;
  onBackspace: () => void;
}

const NumPad: React.FC<NumPadProps> = ({ onButtonClick, onBackspace }) => {
  const handleNumericButtonClick = (value: string) => {
    onButtonClick(value);
  };

  return (
    <div className={styles.numericKeyboard}>
      <div className={styles.numericKeyboard__row}>
        <button onClick={() => handleNumericButtonClick("1")}>1</button>
        <button onClick={() => handleNumericButtonClick("2")}>2</button>
        <button onClick={() => handleNumericButtonClick("3")}>3</button>
      </div>
      <div className={styles.numericKeyboard__row}>
        <button onClick={() => handleNumericButtonClick("4")}>4</button>
        <button onClick={() => handleNumericButtonClick("5")}>5</button>
        <button onClick={() => handleNumericButtonClick("6")}>6</button>
      </div>
      <div className={styles.numericKeyboard__row}>
        <button onClick={() => handleNumericButtonClick("7")}>7</button>
        <button onClick={() => handleNumericButtonClick("8")}>8</button>
        <button onClick={() => handleNumericButtonClick("9")}>9</button>
      </div>
      <div className={styles.numericKeyboard__row}>
        <button onClick={() => handleNumericButtonClick("0")}>0</button>
        <button onClick={onBackspace}>
          <svg
            width="20"
            height="15"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.5 9.5L0.5 9.5M0.5 9.5L9.5 0.5M0.5 9.5L9.5 18.5"
              stroke="#2D3A45"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default NumPad;
