import React from "react";
import styles from "./historyTransactionModal.module.css";
import HistoryOrderList from "../HistoryOrderList/historyOrderList";
import Title from "../Title/title";

interface Props {
  onPress?: () => void;
  handleClose?: () => void;
  handleConfirmed?: () => void;
  open: Boolean;
}

const HistoryTransactionModal: React.FC<Props> = ({
  open,
  onPress,
  handleClose,
  handleConfirmed,
}) => {
  return (
    <div
      className={`${styles.popup}`}
      style={{
        opacity: open ? "1" : "0",
        visibility: open ? "inherit" : "hidden",
      }}
      onClick={onPress}
    >
      <div
        className={styles.modal}
        style={{
          scale: open ? "1" : "0.95",
          transition: "0.1s linear",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Title
          title="История заказа"
          style={{ position: "absolute", top: "25px", left: "6.7%" }}
        />
        <HistoryOrderList />
        <div className={styles.closeBtn} onClick={onPress}>
          <svg
            onClick={handleClose}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M18 6L6 18"
              stroke="#000"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke="#000"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        {/* {orderDetail?.orderItems?.map((e, idx) => (
          <ListItem
            key={idx}
            img={e.product.name}
            name={e.product.name}
            price={e.price}
          />
        ))} */}
      </div>
    </div>
  );
};

export default HistoryTransactionModal;
