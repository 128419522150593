import { Backdrop } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { COLORS } from "../../constants/colors";
import useRootStore from "../../hook/useRootStore";
import { APP_ROUTES } from "../../router/Route";
import Title from "../Title/title";
import styles from "./confirmOrder.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState, store } from "../../store";
import "../Check/Check.css";
import Check from "../Check/Check";
import { renderToStaticMarkup, renderToString } from "react-dom/server";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ConfirmOrder = () => {
  const { visiable, hide } = useRootStore().visiibleStore;
  const navigation = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const { id } = useParams();

  const success = () => {
    toast.success("Спасибо за покупку!", {
      autoClose: 2000,
    });
  };

  const Submit = () => {
    dispatch.orderSlice.orderSend({
      data: { shop_id: id },
      callback: () => {
        
        dispatch.profileSlice.logout();
        navigation(APP_ROUTES.MAIN);
      },
    });
    hide("order");
    hide("confirmOrder");
  };

  const { userList } = useSelector((state: RootState) => state.profileSlice);

  const BackOrder = () => {
    hide("confirmOrder");
  };

  useEffect(() => {
    dispatch.profileSlice.getUser();
  }, []);

  return (
    <>
      <ToastContainer position="top-center" />
      <div className={styles.container}>
        <Backdrop open={visiable.confirmOrder}>
          <div className={styles.content}>
            <div className={styles.titleBox}>
              <Title title="Вы подтверждаете заказ?" fontSize="28px" />
            </div>
            <div className={styles.btnBox}>
              <Title
                onPress={() => {
                  Submit();
                }}
                style={{ cursor: "pointer" }}
                title="Да"
                color={COLORS.grey}
                fontSize="28px"
              />
              <Title
                onPress={() => BackOrder()}
                style={{ cursor: "pointer" }}
                title="Нет"
                color={COLORS.grey}
                fontSize="28px"
              />
            </div>
          </div>
        </Backdrop>
      </div>
    </>
  );
};

export default observer(ConfirmOrder);
