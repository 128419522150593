import React, { useEffect, useState } from "react";
import { COLORS } from "../../constants/colors";
import Text from "../Text/text";
import Title from "../Title/title";
import styles from "./historyList.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../store";
import HistoryTransactionModal from "../historyTransactionModal/historyTransactionModal";

const HistoryList = () => {
  const dispatch = useDispatch<Dispatch>();
  const [historyModal, setHistoryModal] = useState(false);

  useEffect(() => {
    dispatch.orderSlice.getOrderLoad();
  }, []);

  const { ordersList } = useSelector((state: RootState) => state.orderSlice);
  const { getAllOrders } = useSelector((state: RootState) => state.orderSlice);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.listBox} style={{ backgroundColor: "#fafafa" }}>
          <div className={styles.list}>
            <Text
              text="Остаток на начало месяца:"
              style={{ color: "black", fontSize: "17px" }}
            />
          </div>
          <div className={styles.list}>
            <Text
              text={
                getAllOrders?.summa_begin_month?.toLocaleString("ru-RU") || ""
              }
              style={{
                color: COLORS.orange,
                textAlign: "right",
                fontSize: "17px",
              }}
            />
          </div>
          <div className={styles.list}></div>
        </div>
        <div
          className={styles.listBox}
          style={{ backgroundColor: COLORS.orange }}
        >
          <div className={styles.list}>
            <Text
              text="Дата"
              style={{ color: COLORS.white, fontSize: "17px" }}
            />
          </div>
          <div className={styles.list}>
            <Text
              text="Операция"
              style={{ color: COLORS.white, fontSize: "17px" }}
            />
          </div>
          <div className={styles.list}>
            <Text
              text="Приход"
              style={{ color: COLORS.white, fontSize: "17px" }}
            />
          </div>
          <div className={styles.list}>
            <Text
              text="Расход"
              style={{ color: COLORS.white, fontSize: "17px" }}
            />
          </div>
        </div>
        <div className={styles.HistoryListWrapper}>
          {ordersList.map((item) => (
            <div
              className={styles.listBox}
              style={{ borderColor: COLORS.orange }}
              onClick={() => {
                dispatch.orderSlice.getDetailOrder(item.order_id);
                setHistoryModal(true);
              }}
            >
              <div className={styles.list}>
                <Text text={item.created_at?.toLocaleString("ru-RU")} />
              </div>
              <div className={styles.list}>
                <Text text={item?.payment_type} />
              </div>
              <div className={styles.list} style={{ textAlign: "right" }}>
                <Text text={`${item.income?.toLocaleString("ru-RU")} `} />
              </div>
              <div
                className={styles.list}
                style={{ textAlign: "right", marginRight: "100px" }}
              >
                <Text
                  text={
                    item.outcome === null
                      ? "-"
                      : item.outcome?.toLocaleString("ru-RU")
                  }
                />
              </div>
            </div>
          ))}
        </div>
        <div
          className={styles.listBox}
          style={{ backgroundColor: COLORS.orange }}
        >
          <div className={styles.list}>
            <Text
              text="Всего:"
              style={{ color: COLORS.white, fontSize: "17px" }}
            />
          </div>
          <div className={styles.list}>
            {/* <Text text='Операция' style={{ color: COLORS.white }} /> */}
          </div>
          <div className={styles.list}>
            <Text
              text={getAllOrders.summa_income?.toLocaleString("ru-RU") || ""}
              style={{
                color: COLORS.white,
                textAlign: "right",
                marginRight: "75px",
                fontSize: "17px",
              }}
            />
          </div>
          <div className={styles.list}>
            <Text
              text={getAllOrders.summa_outcome?.toLocaleString("ru-RU") || ""}
              style={{
                color: COLORS.white,
                textAlign: "right",
                marginRight: "100px",
                fontSize: "17px",
              }}
            />
          </div>
        </div>
        <div className={styles.total} style={{ backgroundColor: "#fff" }}>
          <div className={styles.list}>
            <Text
              text="Остаток на конец периода:"
              style={{ color: "black", fontSize: "17px" }}
            />
          </div>
          <div className={styles.list}>
            <Text
              text={
                getAllOrders?.summa_end_month?.toLocaleString("ru-RU") || ""
              }
              style={{
                color: COLORS.orange,
                textAlign: "right",
                fontSize: "17px",
              }}
            />
          </div>
          <div className={styles.list}></div>
        </div>
      </div>
      <HistoryTransactionModal
        open={historyModal}
        handleClose={() => setHistoryModal(false)}
        onPress={() => setHistoryModal(false)}
      />
    </>
  );
};

export default HistoryList;
