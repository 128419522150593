import React, { useEffect, useState } from "react";
import styles from "./modalBirthday.module.css";
import NumPad from "../NumPad/NumPad";
import Button from "../Button/button";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../router/Route";

interface Props {
  onPress?: () => void;
  exitFromSystem: any;
  open: Boolean;
  userInfo: any;
}

type ResponseType = {
  success: boolean;
  data: string;
};

const BranchModal: React.FC<Props> = ({ open, onPress, exitFromSystem, userInfo }) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const [stepModal, setStepModal] = useState(1);
  const [birthdayValue, setBirthdayValue] = useState("");
  const [showNumericKeyboard, setShowNumericKeyboard] = useState(false);

  useEffect(() => {
    dispatch.OtherSlice.getBranches();
  }, []);

  const handleNumericButtonClick = (value: string) => {
    if (birthdayValue.length < 10) {
      setBirthdayValue((prevValue) => {
        if (prevValue.length === 2 || prevValue.length === 5) {
          return prevValue + "-" + value;
        } 
        return prevValue + value;
      });
    }
  };

  const handleBackspace = () => {
    setBirthdayValue((prevValue) => prevValue.slice(0, -1));
  };

  const isValidDate = (dateString: string) => {
    const regex = /^\d{2}-\d{2}-\d{4}$/;
    if (!regex.test(dateString)) {
      return false;
    }

    const [day, month, year] = dateString.split("-").map(Number);
    const date = new Date(year, month - 1, day);

    return (
      date.getDate() === day &&
      date.getMonth() === month - 1 &&
      date.getFullYear() === year &&
      new Date().getFullYear() - year <= 120
    );
  };

  const sendNewBirthday = async () => {
    if (!isValidDate(birthdayValue)) {
      toast.error("Некорректная дата рождения");
      return;
    }

    const api = "https://api.smart-dunyo.uz/api/v1/user/birthday";
    const data = {
      birthday: birthdayValue,
    };

    await fetch(api, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${localStorage.getItem("@token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.birthday === birthdayValue) {
          toast.success("День рождения успешно изменен!");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      });
  };

  const handleCloseModal = () => {
    if (onPress && exitFromSystem) {
      onPress();
      setBirthdayValue("");
      setShowNumericKeyboard(false);
    } else {
      toast.error("Смените дату рождения! Выход из системы!");
      setTimeout(() => {
        logout();
      }, 4000);
    }
  };

  useEffect(() => {
    if (!open) {
      setStepModal(1);
    }
  }, [open]);

  const logout = () => {
    localStorage.removeItem("@token");
    localStorage.removeItem("showedInstructions");
    localStorage.removeItem("@checkToken");
    dispatch.profileSlice.logout();
    navigation(APP_ROUTES.BANNER);
  };

  return (
    <>
      <div
        className={`${styles.popup}`}
        style={{
          opacity: open ? "1" : "0",
          visibility: open ? "inherit" : "hidden",
        }}
        onClick={handleCloseModal}
      >
        <div
          className={styles.modal}
          style={{
            scale: open ? "1" : "0.95",
            transition: "0.1s linear",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {stepModal === 1 && (
            <>
              <div style={{ fontSize: "24px" }}>Изменить день рождения</div>
              <div
                style={{
                  width: "100%",
                  margin: "auto",
                  fontSize: "18px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h4 className={styles.labelInput}>Текущий день рождения</h4>
                <input
                  type="text"
                  style={{
                    width: "100%",
                    padding: "12px",
                    margin: "auto",
                    fontSize: "18px",
                    marginBottom: "20px",
                  }}
                  name="oldBirthday"
                  placeholder="День Рождения"
                  readOnly={true}
                  value={userInfo.birthday}
                  id="userOldBirthday"
                />

                <h4 className={styles.labelInput}>Действительный день рождения</h4>
                <input
                  type="text"
                  style={{
                    width: "100%",
                    padding: "12px",
                    margin: "auto",
                    fontSize: "18px",
                  }}
                  name="newBirthday"
                  placeholder="дд-мм-гггг"
                  id="userNewBirthday"
                  value={birthdayValue}
                  onClick={() => {
                    setShowNumericKeyboard(!showNumericKeyboard);
                  }}
                  onChange={(e) => {
                    // Allow only numbers and hyphens
                    const sanitizedValue = e.target.value.replace(/[^0-9-]/g, "");
                    setBirthdayValue(sanitizedValue);
                  }}
                />
              </div>
              <div className={styles.buttons}>
                <Button
                  btnSize="large"
                  btnType="primary"
                  title="Изменить"
                  style={{ width: "48%" }}
                  onPress={sendNewBirthday}
                  disabled={birthdayValue.length !== 10}
                />
                <Button
                  onPress={handleCloseModal}
                  btnSize="large"
                  btnType="primary"
                  title="Отмена"
                  style={{ width: "48%", background: "grey" }}
                />
              </div>
              {showNumericKeyboard ? (
                <div className={styles.keyBoard}>
                  <NumPad
                    onButtonClick={handleNumericButtonClick}
                    onBackspace={handleBackspace}
                  />
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BranchModal;
