import { $api } from "../../contants/API";
import { createModel } from "@rematch/core";
import { RootModel } from "../modals";
import { initialState } from "./state";
import { ShopListType, getSlider, branchListType } from "./type";
import axios from "axios";

export const OtherSlice = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setSlider: (state, payload: getSlider[]) => {
      return {
        ...state,
        sliderList: payload,
      };
    },
    setSliderNotToken: (state, payload: getSlider[]) => {
      return {
        ...state,
        sliderListNotToken: payload,
      };
    },
    setShopsFunction: (state, payload: ShopListType[]) => {
      return {
        ...state,
        shopList: payload,
      };
    },
    setCategoryIdValue: (state, payload) => {
      return {
        ...state,
        categoryIdValue: payload,
      };
    },
    setBranches: (state, payload: branchListType[]) => {
      return {
        ...state,
        branchesList: payload,
      };
    },
  },

  effects: (dispatch) => ({
    async getSliderLoad() {
      try {
        const { data } = await $api.get("v1/slider/index?shop_id=1");
        dispatch.OtherSlice.setSlider(data.data);
      } catch (e) {}
    },
    async getSliderNotToken(id: string) {
      try {
        const { data } = await $api.get(`v1/main-slider/index?shop_id=${id}`);
        dispatch.OtherSlice.setSliderNotToken(data.data);
      } catch (e) {}
    },
    async getShops() {
      try {
        const { data } = await $api.get("v1/shop/index");
        dispatch.OtherSlice.setShopsFunction(data.data.data);
      } catch (e) {}
    },
    async getBranches() {
      try {
        const { data } = await $api.get("v1/perem/branches");
        dispatch.OtherSlice.setBranches(data);
      } catch (e) {}
    },
    async changeBranch(id) {
      try {
        const { data } = await $api.post("v1/perem/create", { branch_in: id });
        return data;
      } catch (e) {}
    },
    async peremDeactiv() {
      try {
        const { data } = await $api.post("v1/perem/deactiv");
        return data;
      } catch (e) {}
    },
  }),
});
