import { useEffect, useRef } from "react";
import "./App.css";
import Router from "./router/router";
import { redirect } from "react-router-dom";

function App() {
  const requestOptions: RequestInit = {
    method: "GET",
  };

  function sendDataToCheckIfWorking() {
    const objectNumber = localStorage.getItem("object_id");
    const monitorNumber = localStorage.getItem("monitor_id");

    if (!objectNumber || !monitorNumber)
      return console.log("objectNumber or monitorNumber is not defined");
  
    fetch(`https://monoblock.smart-dunyo.uz/add/${objectNumber}/${monitorNumber}`, requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    const interval = setInterval(() => {
      sendDataToCheckIfWorking();
    }, 3600000);

    return () => clearInterval(interval);
  }, []);

  return <Router />;
}

export default App;
