import GreyText from "../GreyText/greyText";
import Text from "../Text/text";
import Title from "../Title/title";
import styles from "./historyOrderList.module.css";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const HistoryOrderList = () => {
  const { orderDetail } = useSelector((state: RootState) => state.orderSlice);

  return (
    <div className={styles.container}>
      <div className={styles.infoBox}>
        <GreyText
          text="Товары"
          style={{
            flex: 3,
            color: "rgb(255, 156, 32)",
            fontWeight: "bold",
            fontSize: "22px",
          }}
        />
        <GreyText
          text="Кол-во"
          style={{
            flex: 1,
            color: "rgb(255, 156, 32)",
            fontWeight: "bold",
            fontSize: "22px",
          }}
        />
        <GreyText
          text="Цена"
          style={{
            flex: 1,
            textAlign: "right",
            paddingRight: "50px",
            color: "rgb(255, 156, 32)",
            fontWeight: "bold",
            fontSize: "22px",
          }}
        />
        <GreyText
          text="Итоговая сумма"
          style={{
            flex: 1,
            textAlign: "right",
            color: "rgb(255, 156, 32)",
            fontWeight: "bold",
            fontSize: "18px",
            marginTop: "5px",
          }}
        />
      </div>
      <div style={{ maxHeight: "500px", overflowY: "auto" }}>
        {orderDetail?.orderItems?.map((e, index) => {
          return (
            <div className={styles.listBox} key={index}>
              <div className={styles.nameBox}>
                <Text text={e.product.name} />
              </div>
              <div className={styles.same}>
                <Text
                  text={e.count}
                  style={{ marginLeft: "15px", textAlign: "center" }}
                />
              </div>
              <div className={styles.same}>
                <Title
                  title={`${e.price?.toLocaleString("ru-RU")} сум`}
                  fontSize="15px"
                />
              </div>
              <div className={styles.same}>
                <Title
                  title={`${e.total_price?.toLocaleString("ru-RU")} сум`}
                  fontSize="15px"
                />
              </div>
            </div>
          );
        })}
        <Title
          style={{ marginTop: "10px", fontSize: "24px", width: "100%", textAlign: "right" }}
          title={`Итого:`+'\xa0\xa0\xa0\xa0\xa0'+`${orderDetail?.total_price?.toLocaleString("ru-RU")} сум`}
          fontSize="15px"
        />
      </div>
    </div>
  );
};

export default HistoryOrderList;
