const objectId = localStorage.getItem("object_id");

export const APP_ROUTES = {
  WELCOME: `/`,
  AUTH: `/${objectId}/auth`,
  FACE_ID: `/${objectId}/auth/face-id`,
  TOUCH_ID: `/${objectId}/auth/touch-id`,
  LOGIN: `/${objectId}/login`,
  SIGNIN: `/${objectId}/sign-in`,
  FACE_ID_REG: `/${objectId}/face-id-reg`,
  FACE_ID_REG_SUCCESS: `/${objectId}/face-id-reg-success`,
  AUTHORIZATION: `/${objectId}/auth`,
  BANNER: `/${objectId}/banner`,
  MAIN: `/${objectId}/main`,
  MARKET: `/${objectId}/market/:id`,
  FAVORITES: `/${objectId}/favorites`,
  TRANSACTION: `/${objectId}/transaction`,
  ACCESS: `/${objectId}/access`,
} as const;
