import React, { useEffect, useRef, useState, ChangeEvent } from "react";
import Button from "../../../components/Button/button";
import Input from "../../../components/Input/input";
import LogoName from "../../../components/LogoName/logoName";
import Title from "../../../components/Title/title";
import styles from "./signin.module.css";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-simple-keyboard/build/css/index.css";
import NumPad from "../../../components/NumPad/NumPad";
import { APP_ROUTES } from "../../../router/Route";
import { useNavigate, useLocation } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { useSelector } from "react-redux";

const Login = () => {
  const navigation = useNavigate();
  const [idValue, setIdValue] = useState("");
  const [passValue, setPassValue] = useState("");
  const [focusedInput, setFocusedInput] = useState("id");
  const [showNumericKeyboard, setShowNumericKeyboard] = useState(false);
  const location = useLocation();
  const pathname = location.pathname;

  const getUser = async () => {
    try {
      const token = localStorage.getItem("@token");
      const api = "https://api.smart-dunyo.uz/api/v1/user/info";
      const data = {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(api, data);
      const result = await response.json();
      return result;
    } catch (e) {}
  };

  const handleOnUserIdle = () => {
    if (pathname === APP_ROUTES.SIGNIN) navigation(APP_ROUTES.WELCOME);
  };

  useIdleTimer({
    timeout: 20000,
    onIdle: handleOnUserIdle,
    debounce: 1000,
  });

  const handleNumericButtonClick = (value: string) => {
    if (idValue.length > 7 || passValue.length > 7) return;

    if (focusedInput === "id") {
      setIdValue(idValue + value);
    } else {
      setPassValue(passValue + value);
    }
  };

  const handleBackspace = () => {
    if (focusedInput === "id") {
      setIdValue(idValue.slice(0, -1));
    } else {
      setPassValue(passValue.slice(0, -1));
    }
  };

  const signin = () => {
    const api = "https://api.smart-dunyo.uz/api/v1/user/sign-in";
    const data = {
      id: idValue,
      password: passValue,
    };

    fetch(api, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        localStorage.setItem("@token", data.data);
        if (data.success) {
          const userInfo = await getUser();
          const userObjectId = userInfo.branch_name
            .slice(0, 2)
            .replace(/\D/g, "");
          if (userObjectId == localStorage.getItem("object_id")) {
            navigation(APP_ROUTES.MAIN);
          } else {
            toast.error(`Вы АКТИВНЫ в ${userInfo.branch_name} колонии!`,{
              autoClose: 20000,
            });
            toast.error(`Обратитесь к своему бухагалтеру для перевода в эту колонию!`,{
              autoClose: 20000,
            });
          }
        } else {
          toast.error("Неверный логин или пароль",{
            autoClose: 10000,
          });
          toast.error("Или вы НЕ АКТИВНЫ",{
            autoClose: 10000,
          });
          toast.error("Если вы НЕ АКТИВНЫ, обратитесь к своему бухагалтеру!",{
            autoClose: 10000,
          });
        }
      });
  };

  return (
    <>
      <ToastContainer position="top-center" />
      <div className={styles.container}>
        <div className={styles.topBox}>
          <LogoName />
          <Title title="Вход в личный кабинет" />
        </div>
        <div className={styles.bottomBox}>
          <Input
            label="ID"
            placohlder="Введите свой ID"
            valueInput={idValue}
            onClick={() => {
              setShowNumericKeyboard(true);
              setFocusedInput("id");
            }}
          />
          <Input
            label="Пароль"
            placohlder="Введите свой пароль"
            valueInput={passValue}
            type="text"
            onClick={() => {
              setShowNumericKeyboard(true);
              setFocusedInput("password");
            }}
          />
          <Button
            btnSize="large"
            btnType="primary"
            title="Вход"
            style={{ width: "100%", marginTop: "20px" }}
            onPress={signin}
            disabled={passValue.length < 6 || passValue.length > 8}
          />
        </div>
        {showNumericKeyboard ? (
          <NumPad
            onButtonClick={handleNumericButtonClick}
            onBackspace={handleBackspace}
          />
        ) : null}
      </div>
    </>
  );
};

export default Login;
